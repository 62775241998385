import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { EnvelopeFill, Facebook } from "react-bootstrap-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import "./AboutPage.css";

const AboutPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Container className="about-page">
      <Row className="justify-content-center mb-5 align-items-center">
        <Col lg={8} data-aos="fade-up">
          <h1 className="about-title">Giới thiệu</h1>
          <p className="about-description">
            Thi Nail là nền tảng tiên phong trong việc giúp bạn đạt được chứng
            chỉ nail một cách dễ dàng và hiệu quả. Chúng tôi cung cấp hệ thống
            bài kiểm tra thực hành toàn diện, luôn sẵn sàng đồng hành cùng bạn
            trên hành trình chinh phục sự nghiệp. Với Thi Nail, bạn không chỉ
            chuẩn bị cho kỳ thi, mà còn xây dựng nền tảng vững chắc để thành
            công trong ngành nail chuyên nghiệp.
          </p>
          <p className="about-description">
            Nếu bạn có bất kỳ câu hỏi nào hoặc cần hỗ trợ, đừng ngần ngại liên
            hệ với chúng tôi qua
            <strong> email </strong> hoặc gửi tin nhắn qua{" "}
            <strong> Facebook</strong>. Đội ngũ hỗ trợ thân thiện và tận tâm của
            chúng tôi luôn sẵn sàng giúp bạn đạt được mục tiêu!
          </p>
        </Col>

        <Col md={6} lg={4} data-aos="fade-left">
          <Card className="about-card shadow">
            <Card.Body>
              <Card.Title>
                <h2>Liên Hệ</h2>
              </Card.Title>
              <div className="contact-info">
                <div className="d-flex align-items-center mb-3">
                  <EnvelopeFill className="icon me-2" />
                  <span>suport.thinail@gmail.com</span>
                </div>
                <div className="d-flex align-items-center">
                  <Facebook className="icon me-2" />
                  <a
                    href="https://www.facebook.com/people/Thi-Nail/61569172920677"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-link p-0"
                  >
                    Facebook Page
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutPage;
