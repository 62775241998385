import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import './Quizzes.css';
import { getQuizzes, getUserProfile, createCheckoutSession } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Quizzes = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [userProfile, setUserProfile] = useState(null);
    const navigate = useNavigate();

    const fetchUserProfileAndQuizzes = useCallback(async () => {
        try {
            // Fetch user profile and quizzes in parallel
            const [profileResponse, quizzesResponse] = await Promise.all([
                getUserProfile(),
                getQuizzes()
            ]);
            
            setUserProfile(profileResponse.data);
            setQuizzes(quizzesResponse.data);
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }, []);

    useEffect(() => {
        fetchUserProfileAndQuizzes();
    }, [fetchUserProfileAndQuizzes]);

    const handleStartTest = (id) => {
        navigate(`/quizzes/${id}`);
    };

    const handlePayment = async (id) => {
        try {
            const response = await createCheckoutSession(id);
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: response.sessionId });
            if (error) {
                console.error('Stripe payment failed:', error);
            }
        } catch (error) {
            console.error('Error creating payment session:', error);
        }
    };

    const canAccessQuiz = (quiz) => {
        // If quiz is free, allow access
        if (quiz.price === 0) return true;
        // If user is premium, allow access
        if (userProfile?.isPremium) return true;
        return false;
    };

    return (
        <Container className="quizzes-container my-5">
            <Row>
                <h2 className="text-center mb-5 text-primary">Đề Luyện Thi</h2>
                {quizzes.map(quiz => (
                    <Col key={quiz._id} lg={3} md={6} className="mb-4">
                        <Card className="quiz-card h-100 shadow-sm">
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className="mb-3">
                                    {quiz.title}
                                    {quiz.price > 0 && !userProfile?.isPremium && (
                                        <Badge bg="info" className="ms-2">
                                            ${quiz.price}
                                        </Badge>
                                    )}
                                   
                                </Card.Title>
                                <Card.Text className="flex-grow-1">{quiz.description}</Card.Text>
                                <div className="mt-auto">
                                    {canAccessQuiz(quiz) ? (
                                        <Button
                                            variant="primary"
                                            onClick={() => handleStartTest(quiz._id)}
                                            className="btn"
                                        >
                                            Bắt Đầu Thi
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="success"
                                            onClick={() => handlePayment(quiz._id)}
                                            className="btn"
                                        >
                                            Mua Đề Thi
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Quizzes;